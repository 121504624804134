$font-size:13px !default;
$blockquote-color:#ddd !default;
$code-background:#333 !default;
$headings:white !default;
$page-width:40em !default;

$fonts:'Iosevka', 'lucida console', courier, monospace !default;

::-moz-selection {
    color:$background; 
    background:$text;
}

* {
	box-sizing:border-box;
}

html,
body {
	background:$background;
	font-family:$fonts;
	font-size:$font-size;
	font-size:calc(#{$font-size} + 0.2vw);
	color:$text;
	padding:0;
	margin:0;
	word-wrap:break-word;
	line-height:1.8;
}

a {
	color:$link;
	border-bottom:1px solid $link;
	text-decoration:none;
}

a:hover {
	color:$link-hover;
	border-bottom:1px solid $link-hover;
}

img {
	display:block;
	width:100%;
}

* > *:last-child {
	margin-bottom:0;
}

* > *:first-child {
	margin-top:0;
}

blockquote {
	margin:0 0 0 1em;
	padding:0;
	font-size:0.9em;
	position:relative;
}

blockquote:before {
	content:'>';
	position:absolute;
	left:-1em;
}

pre {
	background:$code-background;
	padding:1rem;
	-moz-tab-size:4;
	-o-tab-size:4;
	tab-size:4;
	white-space:pre-wrap;
}

code {
	background:$code-background;
	font-size:smaller;
	padding:0.3em 0.6em;
}

pre code {
	padding:0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color:$link;
	font-weight:normal;
}

h1 {
	font-size:1.6em;
}

h2 {
	font-size:1.4em;
}

h3 {
	font-size:1.2em;
}

h4 {
	font-size:1.1em;
}

h5,
h6 {
	font-size:1em;
}

ul {
	margin-left:4ch;
	padding:0;
}

ul li {
	list-style-type:none;
	text-indent:-1ch;
}

ul li:before {
	content:'-';
	display:inline-block;
	width:1ch;
}

ol {
	margin-left:5ch;
	padding:0;
}

hr {
	border:none;
}

hr:before {
	display:block;
	content:'***';
	text-align:center;
	color:$text;
}

.wrapper {
	max-width:100%;
	width:$page-width;
	padding:2em;
}

nav {
	font-size:0.9em;
	display:inline-block;
}

nav a {
	border-bottom:none;
}

.page--footer nav {
	border-top:1px solid $text;
	padding-top:1rem;
}

.page--footer {
	margin-top:2rem;
}

.page--header nav {
	padding-bottom:1rem;
	border-bottom:1px solid $text;
}

.page--header {
	margin-bottom:2rem;
}
