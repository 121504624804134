@charset "utf-8";

$background:#000;
$text:#888;
$link:#aaa;
$link-hover:#ccc;

@import "iosevka";

::selection {
	color:$background;
	background:white;
}

@import "normalize";

// search wrappers 

.wrapper {
	margin:20vh auto;
}

.links {
	list-style-type:none;
	margin-left:2ch;	
}

@media (max-width: 600px) {
	.wrapper {
		margin:0 auto;
	}
}
